@import url("bootstrap/dist/css/bootstrap.min.css");

/* GENERAL */
.right {
    float: right;
}

.clear {
    clear: both;
}

footer {
    border-top: 1px solid #E5E5E5;
    margin-top: 45px;
    padding: 35px 0 36px;
}

.jumbotron label {
    font-size: 14px
}

.cal {
    cursor: pointer
}

.form-actions {
    padding: 19px 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgb(245, 245, 245);
    border-top: 1px solid rgb(229, 229, 229);
}

.top-space {
    margin-top: 5px
}

.file-input {
    padding-top: 4px
}

.checkbox input[type="checkbox"] {
    margin-left: -6px;
}

.checkbox .help-block {
    margin-left: 10px;
    margin-top: 1px;
}

#range-table {
    margin-top: 63px;
}

#met-tooltip {
    cursor: pointer;
}

/* DATATABLE STYLE */
#bulk_actions {
    float: left;
    padding-top: 1.4em
}

#create_new {
    float: right;
    padding-top: 1.4em
}

.dataTables_filter {
    position: absolute;
    left: 150px;
    text-align: left;
    top: 20px;
}

#user_registration #id_has_agreed_to_terms {
    float: left;
}

#user_registration .policy-text {
    margin-left: 20px;
}

@media all {
    .page-break { display: none; }
}

@media print {
    .page-break { display: block; page-break-before: always; }
}

/* PIE CHART STYLE */
.pie-label {
    font-size:8pt;
    text-align:center;
    padding:2px;
    color:#333;
}

.click-through {
    color:#333;
}

/* outage form customizations */
.served-tooltip {
    max-width: 100%;
}

img {
  max-width: 100%;
}
